import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { OktaAuth, UserClaims } from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';
import { EMPTY, from, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { OktaAuthStateService } from '@okta/okta-angular';
import { Admins } from '../models/group.enum';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    public accountActive = signal(true);
    private oktaUser: WritableSignal<UserClaims | undefined> = signal(undefined);

    private readonly oktaAuth = new OktaAuth(environment.okta);
    private readonly http = inject(HttpClient);
    private readonly authService = inject(OktaAuthStateService);

    getUser(): Observable<UserClaims> {
        return this.authService.authState$.pipe(
            switchMap(
                (state) => from(state.isAuthenticated ? this.oktaAuth.getUser() : EMPTY)
                    .pipe(
                        map(this.getAvatar),
                        tap(user => this.oktaUser.set(user)),
                    )
            ),
            shareReplay(),
        );
    }


    appLogin(employeeId: number): Observable<User> {
        return this.http.get<User>(`${environment.urls.api}user/${employeeId}/info`).pipe(
            tap(t => this.accountActive.set(t.isActive))
        );
    }


    logout(): Observable<boolean> {
        return from(this.oktaAuth.signOut());
    }


    isAdmin(): Observable<boolean> {
        return this.getGroups().pipe(
            map(m => m.some(s => Admins.includes(s))),
        );
    }


    getGroups(): Observable<string[]> {
        return this.getClaim('groups').pipe(
            map(m => m ? m.split(',') : []),
        );
    }


    getFamilyName(): Observable<string> {
        return this.getClaim('family_name');
    }


    getGivenName(): Observable<string> {
        return this.getClaim('given_name');
    }


    getDisplayName(): Observable<string> {
        return this.getClaim('name');
    }


    getEmployeeId(): Observable<string> {
        return this.getClaim('employee_id');
    }


    getEmail(): Observable<string> {
        return this.getClaim('email');
    }


    getNtId(): Observable<string> {
        return this.getClaim('NTID');
    }


    private getAvatar(user: UserClaims): UserClaims {
        const palette = ['#00AA55', '#009FD4', '#B381B3', '#000000', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];
        const initials = user.name?.split(' ').map(n => n.substring(0, 1)).join('') || '';
        const charCodes = initials.split('').map(char => char.charCodeAt(0)).join('');
        const color = palette[parseInt(charCodes, 10) % palette.length];
        return { ...user, initials, color };
    }


    private getClaim(claim: string): Observable<string> {
        return this.oktaUser() ? of(this.oktaUser()![claim]?.toString())
            : this.getUser().pipe(
                map(user => user[claim]?.toString())
            );
    }
}
